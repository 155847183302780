import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import { DefaultLayout } from "~components/layout/Default"
import { Arrow } from "~components/atoms/Arrow"
import { HeavyTypoHeader } from "~components/elements/HeavyTypoHeader"
import { SEO } from "~components/Seo"
import cx from "classnames"

import * as styles from "~styles/templates/solution.module.scss"
import { FullWidthImage } from "~components/elements/FullWidthImage"
import { GridColumn, GridRow } from "~components/atoms/Grid"
import { resolveLink } from "~utility/Sink"
import { RteText } from "~components/atoms/RteText"
import { Accordion } from "~components/atoms/Accordion"
import { TextBlockSlice } from "~components/slices/TextBlockSlice"
import { Headline } from "~components/atoms/Headline"

const SolutionTemplate = ({ data }) => {
    const solution = data.solution.data

    const { lang, type, url } = data.solution || {}
    const alternateLanguages = data.solution.alternate_languages || []
    const activeDoc = {
        lang,
        type,
        url,
        alternateLanguages,
    }

    return (
        <DefaultLayout activeDocMeta={activeDoc} title={solution.name.text}>
            <SEO title={solution.name.text} lang={lang} />
            <HeavyTypoHeader
                headline={solution.name.text}
                subline={solution.subline.text}
                image={solution.main_image.url}
                gutterBottom={false}
            />
            <div className={styles.intro}>
                <TextBlockSlice
                    data={{
                        primary: {
                            layout: "2",
                            show_arrow: true,
                            text: solution.intro_text,
                        },
                    }}
                />
            </div>

            {solution.banner_image_1.fluid && (
                <FullWidthImage image={solution.banner_image_1} />
            )}
            <div className={styles.text1}>
                <GridRow width="content">
                    <GridColumn s={12} m={4} l={4}>
                        <Headline variant="h2">
                            {solution.text_1_headline.text}
                        </Headline>
                        {solution.example && (
                            <img
                                className={styles.text1Example}
                                src={solution.example.url}
                                alt=""
                            />
                        )}
                    </GridColumn>
                    <GridColumn s={12} m={8} l={8}>
                        <RteText
                            gutterBottom="large"
                            content={solution.text_1.html}
                        />
                        {solution.text_1_accordion &&
                            solution.text_1_accordion.map((accordion, i) => (
                                <Accordion
                                    key={i}
                                    headline={accordion.title.text}
                                    borderBottom={
                                        solution.text_1_accordion.length ===
                                            1 ||
                                        solution.text_1_accordion.length ===
                                            i + 1
                                    }
                                >
                                    <RteText content={accordion.text.html} />
                                </Accordion>
                            ))}
                    </GridColumn>
                </GridRow>
                <Arrow />
            </div>

            {solution.banner_image_2.fluid && (
                <FullWidthImage image={solution.banner_image_2} />
            )}

            {solution.text_2.html && (
                <div className={styles.text2}>
                    <GridRow width="content">
                        <GridColumn s={12} m={4} l={4}>
                            <Headline gutterBottom={false} variant="h2">
                                {solution.text_2_headline.text}
                            </Headline>
                        </GridColumn>
                        <GridColumn s={12} m={8} l={8}>
                            <RteText content={solution.text_2.html} />
                        </GridColumn>
                    </GridRow>
                </div>
            )}

            {solution.explosion.length > 0 && <Explosion solution={solution} />}

            {solution.text_3_headline.text && (
                <div className={styles.text3}>
                    <GridRow width="content">
                        <GridColumn s={12} m={4} l={4}>
                            <Headline variant="h2">
                                {solution.text_3_headline.text}
                            </Headline>
                        </GridColumn>
                        <GridColumn s={12} m={8} l={8}>
                            <RteText content={solution.text_3.html} />
                        </GridColumn>
                    </GridRow>
                </div>
            )}
        </DefaultLayout>
    )
}

const Explosion = ({ solution }) => {
    const [pointer, setPointer] = useState(0)

    return (
        <div className={styles.explosion}>
            <GridRow width="content">
                <GridColumn s={12} m={3} l={4}>
                    <ul className={styles.explosionNav}>
                        {solution.explosion.map((item, i) => (
                            <li
                                key={item.title.text}
                                onMouseEnter={() => setPointer(i)}
                                className={cx(styles.explosionNavListItem, {
                                    [styles.explosionNavListItemIsActive]:
                                        i == pointer,
                                })}
                            >
                                {item.title.text}
                            </li>
                        ))}
                    </ul>
                </GridColumn>
                <GridColumn s={12} m={9} l={8}>
                    {solution.explosion.map((item, i) => (
                        <div
                            key={item.title.text}
                            className={cx(styles.explosionItem, {
                                [styles.explosionItemIsVisible]: i == pointer,
                            })}
                        >
                            {item.product.document && (
                                <div className={styles.explosionItemLink}>
                                    <Link
                                        to={resolveLink(item.product.document)}
                                    >
                                        <img
                                            className={
                                                styles.explosionItemProductLogo
                                            }
                                            src={
                                                item.product.document.data.logo
                                                    .url
                                            }
                                        />
                                    </Link>
                                    <Link
                                        to={resolveLink(item.product.document)}
                                    >
                                        mehr erfahren
                                    </Link>
                                </div>
                            )}
                            <img
                                className={styles.explosionItemImage}
                                src={item.image.url}
                            />

                            <h4>{item.title.text}</h4>
                            <RteText content={item.text.html} />
                        </div>
                    ))}
                </GridColumn>
            </GridRow>
        </div>
    )
}

export default SolutionTemplate

export const query = graphql`
    query Solution($uid: String!, $lang: String!) {
        solution: prismicSolution(uid: { eq: $uid }, lang: { eq: $lang }) {
            uid
            lang
            alternate_languages {
                uid
                type
                lang
                url
            }
            data {
                subline {
                    text
                }
                name {
                    text
                }
                intro_text {
                    html
                }
                example {
                    url
                }
                abstract {
                    text
                }
                text_1 {
                    html
                }
                text_1_headline {
                    text
                }
                text_1_accordion {
                    title {
                        text
                    }
                    text {
                        html
                    }
                }
                text_2 {
                    html
                }
                text_2_headline {
                    text
                }
                text_3 {
                    html
                }
                text_3_headline {
                    text
                }
                explosion {
                    image {
                        url
                    }
                    product {
                        document {
                            ... on PrismicProduct {
                                uid
                                type
                                data {
                                    name {
                                        text
                                    }
                                    logo {
                                        url
                                    }
                                }
                            }
                        }
                    }
                    text {
                        html
                    }
                    title {
                        text
                    }
                }
                main_image {
                    url(imgixParams: { w: 1400 })
                    tabletImage: url(imgixParams: { w: 1000 })
                    mobileImage: url(imgixParams: { w: 700 })
                }

                banner_image_1 {
                    dimensions {
                        height
                        width
                    }
                    fluid(maxWidth: 2000, maxHeight: 600) {
                        ...GatsbyPrismicImageFluid
                    }
                }

                banner_image_2 {
                    dimensions {
                        height
                        width
                    }
                    fluid(maxWidth: 2000, maxHeight: 600) {
                        ...GatsbyPrismicImageFluid
                    }
                }
            }
        }
    }
`
