import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import { container } from "~styles/elements/image/full-width-image.module.scss"

export const FullWidthImage = ({ image }) => {
    const { fluid, dimensions } = image

    return (
        <div className={container}>
            <GatsbyImage
                loading="lazy"
                alt=""
                layout="fullWidth"
                width={dimensions.width}
                height={dimensions.height}
                image={{
                    images: {
                        sources: [{ srcSet: fluid.srcSet }],
                    },
                    aspectRatio: fluid.aspectRatio,
                    placeholder: { fallback: fluid.base64 },
                }}
            />
        </div>
    )
}
