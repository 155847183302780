import React from "react"
import { Arrow } from "~components/atoms/Arrow"
import { ContentSection } from "~components/atoms/ContentSection"
import { GridColumn, GridRow } from "~components/atoms/Grid"

import * as styles from "~styles/elements/slices/text-block.module.scss"

import cx from "classnames"
import { RteText } from "~components/atoms/RteText"

export function TextBlockSlice({ data }) {
    const item = data.primary
    return (
        <div className={cx(styles.root, styles[`theme${item.layout}`])}>
            <ContentSection>
                <GridRow width="content">
                    <GridColumn s={12} m={12} l={12}>
                        <article>
                            <RteText content={item.text.html} />
                        </article>
                    </GridColumn>
                </GridRow>
                {item.show_arrow && <Arrow gutterBottom={false} />}
            </ContentSection>
        </div>
    )
}
