import React, { useState } from "react"
import * as styles from "~styles/atoms/accordion.module.scss"
import cx from "classnames"
import { Headline } from "~components/atoms/Headline"
import IconPlus from "~images/icon/icon-plus.svg"
import IconMinus from "~images/icon/icon-minus.svg"

export const Accordion = ({
    children,
    className,
    headline,
    gutterBottom = false,
    borderBottom = false,
    isOpened = false,
    ...others
}) => {
    const [isActive, setIsActive] = useState(isOpened)

    return (
        <div
            className={cx(
                styles.root,
                { [styles.gutterBottom]: gutterBottom },
                { [styles.borderBottom]: borderBottom },
                className
            )}
            {...others}
        >
            <header
                className={cx(styles.header, {
                    [styles.headerIsOpened]: isActive,
                })}
                onClick={() => setIsActive(!isActive)}
            >
                <Headline variant="h3">{headline}</Headline>
                <img src={isActive ? IconMinus : IconPlus} />
            </header>
            <div
                className={cx(styles.content, {
                    [styles.contentIsOpened]: isActive,
                })}
            >
                {children}
            </div>
        </div>
    )
}
